import React from 'react'
import { Drawer, Grid, makeStyles, Toolbar, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import PrimaryButton from './GeneralComponents/Buttons/PrimaryButton'
import Routes from '../utilities/Routes'
import LinkComponent from './GatsbyComponents/LinkComponent'
import EmailIcon from '@material-ui/icons/Email'
import CallIcon from '@material-ui/icons/Call'
import clsx from 'clsx'
import LinkButton from './GeneralComponents/Buttons/LinkButton'
import ExpandIcon from '@material-ui/icons/KeyboardArrowDown'
import RightArrow from '@material-ui/icons/ArrowForward'
const useStyles = makeStyles(theme => ({
    drawerPaper: {
        width: "90%",
        maxWidth: "450px"
    },
    backdrop: {
        backgroundColor: "rgba(38, 59, 80, 0.29)"
    },
    borderBottom: {
        borderBottom: "1px solid #c4c4c4"
    },
    image: {
        maxWidth: "90px"
    },
    links: {
        color: theme.palette.text.primary,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: '600',
        textDecoration: 'none',
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px",
        }
    },
    activeLinks: {
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px",
        }
    },
    toolbar: {
        minHeight: "80px"
    },
    icon: {
        padding: "0px",
        margin: '0px'
    },
    accordionSummary: {
        padding: '20px',
        minHeight: "0px !important",
    },
    accordionSummaryExpand: {
        minHeight: "0px !important",
    },
    summaryContent: {
        margin: '0px !important'
    },
    accordionDetails: {
        padding: '20px',
        backgroundColor: 'rgba(29, 161, 242, 0.05)'
    }
}))

export default function DrawerComponent({
    open, onClose, contactData = {}, appBarData = []
}) {
    const classes = useStyles()
    return (
        <Drawer
            anchor='right'
            variant='temporary'
            open={open}
            onClose={onClose}
            BackdropProps={{
                className: classes.backdrop
            }}
            PaperProps={{
                className: classes.drawerPaper
            }}
        >
            <Toolbar className={classes.toolbar} />
            <Grid container direction='column' style={{ height: 'calc(100vh - 80px)' }}>
                {
                    (!!contactData.contactEmail || !!contactData.contactPhone1 || !!contactData.contactPhone2) &&
                    <Grid item style={{ backgroundColor: "#F5F5F5" }}>
                        <div style={{ padding: "20px" }}>
                            <Grid container alignItems='center' justifyContent='flex-end' spacing={2}>
                                {
                                    !!contactData.contactEmail &&
                                    <Grid item xs={12}>
                                        <a href={`mailto:${contactData.contactEmail}`} className={classes.links}>
                                            <Grid container spacing={1} alignItems='center' style={{ flexWrap: 'nowrap' }}>
                                                <Grid item style={{ display: 'flex' }}><EmailIcon style={{ fontSize: '1.3rem' }} color='primary' /></Grid>
                                                <Grid item><Typography className={classes.links}>{contactData.contactEmail}</Typography></Grid>
                                            </Grid>
                                        </a>
                                    </Grid>
                                }
                                {
                                    (!!contactData.contactPhone1 || !!contactData.contactPhone2) &&
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} alignItems='center' style={{ flexWrap: 'nowrap' }} >
                                            <Grid item style={{ display: 'flex' }}><CallIcon style={{ fontSize: '1.3rem' }} color='primary' /></Grid>
                                            <Grid item>
                                                <span>
                                                    {
                                                        !!contactData.contactPhone1 &&
                                                        <span>
                                                            <a href={`tel:${contactData.contactPhone1}`} className={classes.links}>
                                                                <span className={classes.links}>{contactData.contactPhone1}</span>
                                                            </a>
                                                        </span>
                                                    }
                                                    {
                                                        !!contactData.contactPhone1 &&
                                                        <span >
                                                            <span className={classes.links} style={{ whiteSpace: 'break-spaces' }}>  •  </span>
                                                        </span>
                                                    }
                                                    {
                                                        !!contactData.contactPhone2 &&
                                                        <span >
                                                            <a href={`tel:${contactData.contactPhone2}`} className={classes.links}>
                                                                <span className={classes.links}>{contactData.contactPhone2}</span>
                                                            </a>
                                                        </span>
                                                    }
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </Grid>
                }
                <Grid item style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                    <Grid container>
                        {
                            appBarData.filter(item => item.showInDrawer).map(item => {
                                return <Grid item key={item.id} xs={12}>
                                    {
                                        item.showAsAccordion ? <div>
                                            <Accordion className={classes.borderBottom} elevation={0}>
                                                <AccordionSummary classes={{
                                                    root: clsx(classes.accordionSummary),
                                                    content: clsx(classes.summaryContent, classes.links),
                                                    expanded: classes.accordionSummaryExpand,
                                                    expandIcon: classes.icon
                                                }}
                                                    expandIcon={<ExpandIcon color='secondary' />}
                                                >{item.title}</AccordionSummary>
                                                <AccordionDetails classes={{ root: classes.accordionDetails }}>
                                                    <Grid container >
                                                        {
                                                            item.list.map(link => {
                                                                return <Grid item xs={12}>
                                                                    <LinkComponent appendPath={false} className={classes.links} activeClassName={classes.activeLinks} path={link.slug}>
                                                                        <div onClick={onClose} style={{ padding: "10px 0px" }}>
                                                                            {link.title}
                                                                        </div>
                                                                    </LinkComponent>
                                                                </Grid>
                                                            })
                                                        }
                                                        {
                                                            item.showButton &&
                                                            <Grid item xs={12}>
                                                                <div >
                                                                    <LinkButton href={item.buttonRoute}>
                                                                        <Grid container spacing={1} alignItems='center'>
                                                                            <Grid item>
                                                                                <Typography variant='body1' style={{ fontWeight: '600' }} >{item.buttonTitle}</Typography>
                                                                            </Grid>
                                                                            <Grid item style={{ display: 'flex' }}>
                                                                                <RightArrow color='primary' />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </LinkButton>
                                                                </div>
                                                            </Grid>
                                                        }
                                                        {
                                                            item.showButton2 &&
                                                            <Grid item xs={12}>
                                                                <div >
                                                                    <LinkButton href={item.buttonRoute2} target={"_blank"}>
                                                                        <Grid container spacing={1} alignItems='center'>
                                                                            <Grid item>
                                                                                <Typography variant='body1' style={{ fontWeight: '600' }} >{item.buttonTitle2}</Typography>
                                                                            </Grid>
                                                                            <Grid item style={{ display: 'flex' }}>
                                                                                <RightArrow color='primary' />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </LinkButton>
                                                                </div>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div> :
                                            <LinkComponent className={classes.links} activeClassName={classes.activeLinks} path={item.titleRoute}>
                                                <div style={{ padding: "20px" }} className={classes.borderBottom}>
                                                    {item.title}
                                                </div>
                                            </LinkComponent>
                                    }
                                </Grid>
                            })
                        }
                    </Grid>
                </Grid>
                <Grid item>
                    <div style={{ padding: "15px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PrimaryButton href={Routes.contact}>
                            Contact Us
                        </PrimaryButton>
                    </div>
                </Grid>
            </Grid>
        </Drawer>
    )
}
